/* General styling for the table */
.tbl {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    background: transparent;
  }
  
  /* Styling for table headers */
  .tbl thead tr {
    background-color: goldenrod;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  /* Styling for table rows */
  .tbl tbody tr {
    border-bottom: 1px solid #dddddd;
    background-color: transparent; /* Set rows to be transparent */
  }
  
  /* Alternating row colors */
  .tbl tbody tr.row-even {
    background-color: rgba(0, 0, 0, 0.05); /* Slightly darkened for even rows */
  }
  
  .tbl tbody tr.row-odd {
    background-color: rgba(0, 0, 0, 0); /* Fully transparent for odd rows */
  }
  
  /* Hover effects for rows */
  .tbl tbody tr:hover {
    background-color: rgba(255, 215, 0, 0.1); /* Slight goldenrod tint on hover */
  }
  
  /* Styling for table cells */
  .tbl td, .tbl th {
    padding: 12px 15px;
    text-align: left;
  }
  
  /* Specific styling for the status column */
  .status {
    width: 10%;
  }
  
  /* Styling for the transaction hash column */
  .tx {
    width: 20%;
    word-break: break-all; /* Break words to fit within the cell */
  }
  
  /* Specific styling for the block number column */
  .block-number {
    width: 10%;
  }
  
  /* Specific styling for the timestamp column */
  .timestamp {
    width: 20%;
  }
  
  /* Specific styling for the gas used column */
  .gas-used {
    width: 10%;
  }
  
  /* Specific styling for the value column */
  .value {
    width: 10%;
  }
  
  /* Link styling */
  a {
    color: inherit; /* Inherit the text color from parent */
    text-decoration: none;
  }
  
  /*
  a:hover {
    text-decoration: underline;
  }
  */
  
  /* Status badge styling */
  .bg-green-400 {
    background-color: #28a745;
    color: #fff;
    border-radius: 12px;
    padding: 4px 8px;
  }
  
  .bg-yellow {
    background-color: #ffc107;
    color: #fff;
    border-radius: 12px;
    padding: 4px 8px;
  }
  
  /* Set default text color in rows to golden */
  .tbl tbody tr td {
    color: golden;
  }
  
  
  .homebutton-gantevents {
	text-align: left;
	margin-left: 5.5%;
	margin-top: 2%;
  }
  .gnatevents{
	text-align: center;
	color:wheat; 
	font-family: Arial, Helvetica, sans-serif;
  }
  .empty-gnatevents{
	margin-bottom: 10%;
  }
  .box-main-gnatevents {
    text-align: left;
	max-width: 80%;
	margin-top: 2%;
	margin-left: 6%;
}
  .gnatevents_gnatsgif{
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url("assets/6.gif");
  }
 
  