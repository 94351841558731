* {
	box-sizing: border-box;
  }
  
  /* Set a background color */
  body {
	background-color: white;
	font-family: Helvetica, sans-serif;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
  }
  
  /* The actual timeline (the vertical ruler) */
  .timeline::after {
	content: '';
	position: absolute;
	width: 6px;
	background-color: #FF9F55;
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: -3px;
  }

   /* The actual timeline green(the vertical ruler) */
   .timeline-green {
	position: relative;
	max-width: 1200px;
	margin: 0 auto;
  }

    /* The actual timeline green(the vertical ruler) */
	.timeline-green::after {
		content: '';
		position: absolute;
		width: 6px;
		background-color:greenyellow;
		top: 0;
		bottom: 0;
		left: 50%;
		margin-left: -3px;
	  }
  
  /* Container around content */
  .container {
	padding: 10px 40px;
	position: relative;
	background-color: inherit;
	width: 50%;
  }
  
  /* The circles on the timeline */
  .container::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 25px;
	right: -17px;
	background-color: white;
	border: 4px solid #FF9F55;
	top: 15px;
	border-radius: 50%;
	z-index: 1;
  }

   /* Container green around content */
   .container-green {
	padding: 10px 40px;
	position: relative;
	background-color: inherit;
	width: 50%;
  }
  
  /* The circles on the timeline */
  .container-green::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 25px;
	right: -17px;
	background-color: white;
	border: 4px solid greenyellow;
	top: 15px;
	border-radius: 50%;
	z-index: 1;
  }
  
  /* Place the container to the left */
  .left {
	left: 0;
  }
  
  /* Place the container to the right */
  .right {
	left: 50%;
  }
  
  /* Add arrows to the left container (pointing right) */
  .left::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid #FF9F55;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent #FF9F55;
  }

   /* Add arrows to the left green container (pointing right) green */
   .left-green::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	right: 30px;
	border: medium solid greenyellow;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent greenyellow;
  }
  
  /* Add arrows to the right container (pointing left) */
  .right::before {
	content: " ";
	height: 0;
	position: absolute;
	top: 22px;
	width: 0;
	z-index: 1;
	left: 30px;
	border: medium solid #FF9F55;
	border-width: 10px 10px 10px 0;
	border-color: transparent #FF9F55 transparent transparent;
  }

    /* Add arrows to the right green container (pointing left) */
	.right-green::before {
		content: " ";
		height: 0;
		position: absolute;
		top: 22px;
		width: 0;
		z-index: 1;
		left: 30px;
		border: medium solid greenyellow;
		border-width: 10px 10px 10px 0;
		border-color: transparent greenyellow transparent transparent;
	  }
  
  /* Fix the circle for containers on the right side */
  .right::after {
	left: -16px;
  }
  
  /* The actual content */
  .content {
	padding: 20px 30px;
	background-color: #FF9F55;
	position: relative;
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color:black;
	font-weight: bold;
	border-radius: 6px;
  }
    /* The actual green content */
	.content-green {
		padding: 20px 30px;
		background-color:greenyellow;
		position: relative;
		text-align: left;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		color:black;
		font-weight: bold;
		border-radius: 6px;
	  }
  
  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
  /* Place the timelime to the left */
	.timeline::after {
	  left: 31px;
	}
  
  /* Full-width containers */
	.container {
	  width: 100%;
	  padding-left: 70px;
	  padding-right: 25px;
	}
  
  /* Make sure that all arrows are pointing leftwards */
	.container::before {
	  left: 60px;
	  border: medium solid white;
	  border-width: 10px 10px 10px 0;
	  border-color: transparent white transparent transparent;
	}
  
  /* Make sure all circles are at the same spot */
	.left::after, .right::after {
	  left: 15px;
	}
  
  /* Make all right containers behave like the left ones */
	.right {
	  left: 0%;
	}
  }
  /*-----------------my css-------------------------------------------*/
  
.homebutton-roadmap{
	text-align: left;	
	margin-top: 1%;
	margin-left: 2%;
}

.gnatsgif_roadmap{
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url("assets/1.gif");
  }

