.section-game{
	height: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 3.5%;
}
.box-main-game {
    text-align: left;
	max-width: 80%;
	margin-top: 2%;
	margin-left: 6%;
}
.subheading-game{
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    font-weight: bold;  
	color:white;
}
.text-big-game{
	font: message-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 66px;
    font-weight: bold;
    color:white;  
	padding-bottom: 1%;
}
.text-small-game{
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	color:wheat; 
	margin-bottom: 3%;

}
.homebutton-game {
	text-align: left;
	margin-left: 5.5%;
	margin-top: 2%;
  }
  .game-gnats{
	text-align: center;
	color:wheat; 
	font-family: Arial, Helvetica, sans-serif;
  }
  .empty-game{
	margin-bottom: 4%;
  }
  .game_gnatsgif{
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url("assets/39.gif");
  }
 /*------------button---------------
 /* Centering the button */
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; /* Full-screen centering */
    margin-left: 10%;
}

/* Styling the button */
.play-button {
    background-color: yellow;
    color: black;
    font-size: 32px;
    font-weight: bold;
    padding: 16px 48px;
    border: none;
    border-radius: 50px; /* Fully rounded */
    cursor: pointer;
    transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}

/* Hover effect */
.play-button:hover {
    background-color: #ffcc00;
    transform: scale(1.05);
}

/* Click effect */
.play-button:active {
    background-color: #ffaa00;
    transform: scale(0.95);
}
