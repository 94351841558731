
.social-links{	
    justify-content: center;
}
.text-footer {
    text-align: center;
    padding: 4px;
    font-family: Arial, Helvetica, sans-serif;
    color: grey;
    font-weight: normal;
    font-size: 14px;
}
.footer {
    background: transparent;
    text-align: center;
    padding: 10px;
    margin-top: auto; /* Ensure it stays at the bottom */
    width: 100%; /* Ensure it takes full width */
}
