.section-whygnats{
	height: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 3.5%;
}
.box-main-whygnats {
    text-align: left;
	max-width: 80%;
	margin-top: 2%;
	margin-left: 6%;
}
.subheading-why{
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    font-weight: bold;  
	color:white;
}
.text-big-whygnats{
	font: message-box;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 66px;
    font-weight: bold;
    color:white;  
	padding-bottom: 1%;
}
.text-small-whygnats{
	font-size: 15px;
	font-family: Arial, Helvetica, sans-serif;
	color:wheat; 
	margin-bottom: 3%;

}
.homebutton-why {
	text-align: left;
	margin-left: 5.5%;
	margin-top: 2%;
  }
  .why-gnats{
	text-align: center;
	color:wheat; 
	font-family: Arial, Helvetica, sans-serif;
  }
  .empty-why{
	margin-bottom: 4%;
  }
  .why_gnatsgif{
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url("assets/39.gif");
  }
 