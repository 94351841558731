.section-faq{
    height: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 3.5%;
}
.box-main-faq {
	display: flex;
    text-align: left;
	justify-content: center;
	align-items: center;
	color: wheat;
	max-width: 80%;
	margin-top: 5%;
	margin-left: 6%;
}

.gnatsgif_faq{
	z-index: -1;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url("assets/6.gif");
  }
.text-big-faq {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color:black; 
}
.text-small-faq {
	font-size: 15px;
	font-family:Arial, Helvetica, sans-serif;
	font-weight: bold;
	color:blanchedalmond;  
	text-align: left;
	padding-top: 0.5%;
}
.qa-faq{
	width: 85%;
	text-align: center;
	margin-left: 6%;
	margin-bottom: 20px;
}


/*-----------------------------------top------------------------*/
.homebutton-top {
  text-align: left;
  margin-left: 5.5%;
  margin-top: 2%;;
}
.faq-heading{
  text-align: center;
  color: wheat; 
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 2%;
}
.accordian{
	background-color: gold;
}
.emptyspace_faq{
	padding-top: 290px;
}