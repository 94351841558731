/* Reset some basic styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Arial', 'Helvetica', sans-serif;
}

.section-minting {
    height: auto;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2% 0;
}

.box-main-minting {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    width: 90%;
    margin: 0 auto;
}

.secondhalf-minting {
    width: 50%;
    display: block;
    margin: 0 2%;
}

.homebutton-minting {
    text-align: center;
    margin: 4%;
}

.text-big-minting {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 44px;
    font-weight: bold;
    color: darkslateblue;
    padding-bottom: 1%;
    text-align: left;
}

.text-small-minting {
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
    background-color: gold;
    padding: 1%;
    margin: 1% 0;
    border-radius: 8px;
    text-align: left;
}

.subheading-minting {
    font-size: 1.2em;
    font-family: fantasy;
    font-style: italic;
    font-weight: bold;
    padding-bottom: 1%;
    color: darkslateblue;
}
