/* navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: wheat;
    padding: 15px;
    font-weight: bold;
    font-family: 'heading';
    flex-direction: row-reverse;
    flex-wrap: wrap; /* Enable wrapping of flex items */
    font-family: Arial, Helvetica, sans-serif;
}

.nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 5px;
    flex-wrap: wrap; /* Enable wrapping of nav items */
}

.nav-link-box {
    margin: 0 15px; /* Adjust the margin to increase spacing between links */

}

.opensea-button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.opensea-button:hover {
    background-color: darkblue;
}

@media (max-width: 768px) {
    .nav-links {
        width: auto;
        flex-direction: column;
        align-items: center;
    }

    .nav-link-box {
        margin: 5px; /* Adjust for smaller screens */
    }
}

