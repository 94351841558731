.section-about {
	height: auto;
	display: flex;
	align-items: center;
}
.box-main-about {
	display: flex;
    text-align: left;
	justify-content: center;
	align-items: center;
	color: black;
	max-width: 90%;
	margin-top: 3%;
	margin-left: 6%;
}
.secondhalf-about{
	width: 100%;
	display: block;
	margin-left: 4%;
	margin-right: 6%;
}
.homebutton-about{
	text-align: center;
	margin: 4%;
}
.text-big-about {
	font: message-box;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 44px;
    font-weight: bold;
    color:darkslateblue;  
	padding-bottom: 1%;
}
.text-small-about {
	font-size: 14px;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	font-weight: bold;
	background-color:gold;	
	padding: 1%;
    margin: 1% 0;
    border-radius: 8px;
    text-align: left;
}
