.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the main container takes the full viewport height */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1a201b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}






img{
  display: inline-block;
 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.topdiv{
  padding-bottom: 3.5%;
}

.tbl{
  margin-left: auto;
  margin-right: auto;
  font-size: small;
  background-color:black;
  color: wheat;
}

.text-xs {
  font-size: small;
 
}
.status{
  padding-left: 0px;
}
.tx{
  padding-left: 40px;
}

.bg-green-400 {
  background-color: #68d391;
  color: black;
}

.bg-yellow {
  background-color: #f6e05e;
  color: black;
}
.w-36{
  text-align: left;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.mr-2 {
  margin-right: 0.5rem;
}
h1.ge {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: small;
  color:white;
}
h2.ca {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: small;
  color: white;
  padding-bottom: 10px;
}

.mm-center-writeup{
  padding-bottom: 30px;
}