/* maintext.css */
.maintext-container {
    display:flex;
    justify-content: center;
    align-items: center;
    height: 65vh;  
}

.maintext-logo-box {
    text-align: center; /* Center the text and image */
}

.maintext-writeup-text {
    font-size: 14px;
    color: wheat;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}
.maintext-gif {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("assets/41.gif");
}
.mint {
    padding-top: 40px;
}

